import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Button, { LinkButton } from '../components/Button'
import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import YoutubeVideo from '../components/YoutubeVideo'

const title = 'Urlaub in Nauders | Stadlwirt'
const metaDescription =
  'Nauders am Reschenpass ist ein moderner und internationaler Sommer- und Winterurlaubsort mit viel Tradition und Geschichte.'

const NaudersPage = () => {
  const { fluidBackgroundImage } = useStaticQuery(
    graphql`
      query {
        fluidBackgroundImage: file(relativePath: { eq: "nauders.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  return (
    <>
      <SEO title={title} description={metaDescription} />
      <HeroHeader
        title="Nauders"
        staticBackgroundImage="/uploads/nauders.jpg"
        fluidBackgroundImage={fluidBackgroundImage}
      />

      <section className="py-10">
        <div className="container flex flex-wrap items-center">
          <div className="w-full md:w-1/2 md:order-2 md:pt-10">
            <YoutubeVideo videoId="DBvflyLPrtQ" />
          </div>

          <div className="w-full md:w-1/2 md:order-1 md:pr-5">
            <h2 className="text-2xl">Winter</h2>
            <hr className="my-2" />
            <p className="mb-5">
              Nauders ist ein Paradies für Wintersportler und Schneebegeisterte!
              Das einen Katzensprung entfernt vom Stadlwirt liegende Schigebiet
              Nauders 3000 bietet neben 70 Pistenkilometern, einem Funpark,
              Langlaufloipen und Rodelbahn auch 110 km Winterwanderwege an, auf
              denen die Schönheit des Winters besonders zur Geltung kommt.
              <br />
              <br />
              Sie wollen lieber etwas abseits der präparierten Pisten fahren?
              Freuen Sie sich auf Buckelpisten, Schi- und Freeride-Routen soweit
              das Auge reicht. Den Ausblick vom höchsten Punkt des Schigebiets
              auf fast 3000 Metern, sollte sich allerdings jeder gönnen - er
              raubt selbst Einheimischen den Atem!
            </p>
            <a
              href="https://www.nauders.com/de/aktiv-erleben/winter-aktiv-erleben"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Winter in Nauders entdecken</Button>
            </a>
          </div>
        </div>
      </section>

      <section className="py-10 text-white bg-gray-900">
        <div className="container">
          <h2 className="text-2xl">Sie suchen eine Unterkunft?</h2>
          <hr className="my-2" />
          <p className="mb-5">
            Der Stadlwirt bietet wunderschöne Urlaubwohnungen im Herzen von
            Nauders an!
          </p>
          <LinkButton to="/wohnungen">Wohnungen betrachten</LinkButton>
        </div>
      </section>

      <section className="py-10">
        <div className="container flex flex-wrap items-center">
          <div className="w-full md:w-1/2 md:order-2 md:pt-10">
            <YoutubeVideo videoId="YXdprZIAXKQ" />
          </div>

          <div className="w-full md:w-1/2 pr-5 md:order-1">
            <h2 className="text-2xl">Sommer</h2>
            <hr className="my-2" />
            <p className="mb-5">
              Ob als Wanderer, Mountainbiker oder Motorradfahrer - wie Sie die
              idyllische Sommeratmosphäre in Nauders und Umgebung erleben ist
              egal, wichtig ist, das Sie es sich nicht entgehen lassen!
              Wunderschöne Bergseen, mächtige Gipfel sowie tausende Kilometer
              Bike-Routen erwarten Sie. Genießen Sie die Ruhe der Natur beim
              Wandern oder jagen Sie die Action mit dem Mountainbike, die
              atemberaubende Kullise bietet jedem genau das, was er gerade
              möchte.
              <br />
              <br />
              Von Outdoor-Aktivitäten wie Rafting, Canyoning oder Klettern bis
              hin zu geheimnisvollen Kulturschätzen wie dem Schloss Naudersberg
              oder der Festung Nauders, sie werden Geschichten zu erzählen
              haben!
            </p>
            <a
              href="https://www.nauders.com/de/aktiv-erleben/sommer-aktiv-erleben"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Sommer in Nauders entdecken</Button>
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default NaudersPage
